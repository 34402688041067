//authSlice.ts
import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import instance from '../axiosInstance';
import { User } from '@/types';

interface LoginPayload {
    username: string;
    passcode: string;
}

interface AuthState {
    user: User | null;
    isLoading: boolean;
    error: string | null;
    token: string | null;
}

interface ErrorResponse {
    message: string;
}
export const setUser = createAction<User>('auth/setUser');

export const login = createAsyncThunk<User, LoginPayload, { rejectValue: ErrorResponse }>(
    'auth/login',
    async ({ username, passcode }, { rejectWithValue, dispatch }) => {
        try {
            const response = await instance.post<User>('merchant-web-app/login', {
                username,
                passcode
            });
            const accessToken = response.data.AccessToken;
            dispatch(setUser(response.data));
            dispatch(setToken(accessToken));
            instance.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const logout = createAsyncThunk<void, void, { rejectValue: ErrorResponse }>(
    'auth/logout',
    async (_, { rejectWithValue, dispatch, getState }) => {
        try {
            await instance.post('merchant-web-app/logout');
            dispatch(resetAuthState());
            return;
        } catch (error: any) {
            if (error.response) {
                return rejectWithValue(error.response.data || 'Unknown error');
            } else {
                return rejectWithValue(error.message || 'Unknown error');
            }
        }
    }
);

export const redeemVoucher = createAsyncThunk<
    User,
    { voucherCode: string; amount: string },
    { rejectValue: ErrorResponse }
>('auth/redeemVoucher', async ({ voucherCode, amount }, { rejectWithValue }) => {
    try {
        const response = await instance.post<User>('merchant-web-app/voucher-redeem', {
            voucher_code: voucherCode,
            amount: amount
        });

        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});

export const pointsAccumulate = createAsyncThunk<
    User,
    { mobileNumber: string; amount: string },
    { rejectValue: ErrorResponse }
>('auth/pointsAccumulate', async ({ mobileNumber, amount }, { rejectWithValue }) => {
    try {
        const response = await instance.post<User>('merchant-web-app/points-accumulate', {
            mobile_number: mobileNumber,
            amount: amount
        });

        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});

export const pointsRedemptionGetAccounts = createAsyncThunk<
    User,
    { mobileNumber: string; token?: string },
    { rejectValue: ErrorResponse }
>('auth/pointsRedemptionGetAccounts', async ({ mobileNumber, token }, { rejectWithValue }) => {
    try {
        console.log('token', token);

        const response = await instance.post<User>(
            '/merchant-apis/points-redemption-get-accounts',
            {
                mobile_number: mobileNumber,
                token: '5uozB7yGvFInGQ+FHj0svcf4RDGkVuOdHZt9+kosASkxbGlHgro/pFSgRm22yXJymoI0obc0EsI='
            },
            {
                headers: {
                    Authorization: token ? `Bearer ${token}` : undefined
                }
            }
        );

        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});

const initialState: AuthState = {
    user: null,
    isLoading: false,
    error: null,
    token: null
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        // Add a reducer to set the token in the state
        setToken: (state, action) => {
            state.token = action.payload;
        },
        resetAuthState: (state) => initialState // Reset the state to initialState
    },
    extraReducers: (builder) => {
        builder.addCase(login.fulfilled, (state, action) => {
            state.user = action.payload;
            state.isLoading = false;
            state.error = null;
            // Set the token in the state when login is successful
            state.token = action.payload?.AccessToken || action.payload?.AccessToken || null;
        });
        builder.addCase(login.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(login.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload?.message || 'An error occurred';
        });
    }
});

export const { setToken } = authSlice.actions;
export const resetAuthState = createAction<void>('auth/resetState');

export default authSlice.reducer;

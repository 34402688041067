import React from 'react';
import type { AppProps } from 'next/app';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import localFont from 'next/font/local';
import Head from 'next/head';
import Script from 'next/script';
import { useRouter } from 'next/router';
import '@/styles/globals.css';
import Layout from '@/components/layout/layout';
import { Provider } from 'react-redux';
import { store } from '@/stores/store';

const FrutigerFont = localFont({
    src: [
        {
            path: '../fonts/Inter-Black.woff2',
            weight: '400',
            style: 'normal'
        },
        {
            path: '../fonts/Inter-Black.woff2',
            weight: '700',
            style: 'normal'
        }
    ],
    display: 'swap',
    variable: '--font-frutiger'
});

const App = ({ Component, pageProps }: AppProps) => {
    const [queryClient] = React.useState(() => new QueryClient());
    const router = useRouter();

    // Dispatch the setToken action with the token from localStorage

    React.useEffect(() => {
        if (!store.getState().auth.user?.AccessToken && router.pathname !== '/') {
            router.push('/');
        } else if (store.getState().auth.user?.AccessToken && router.pathname === '/') {
            router.push('/home');
        }
    }, [router]);

    return (
        <QueryClientProvider client={queryClient}>
            {/* Apply the font style */}
            <style
                jsx
                global
            >{`:root { --font-frutiger: ${FrutigerFont.style.fontFamily};}}`}</style>
            <main className={FrutigerFont.variable}>
                <Head>
                    <link rel='icon' href='/favicon.ico' sizes='any' />
                    <link
                        rel='icon'
                        href='/icon?<generated>'
                        type='image/<generated>'
                        sizes='<generated>'
                    />
                    <link
                        rel='apple-touch-icon'
                        href='/apple-icon?<generated>'
                        type='image/<generated>'
                        sizes='<generated>'
                    />
                    <meta name='viewport' content='width=device-width, initial-scale=1.0' />
                </Head>
                <Script
                    src='https://www.googletagmanager.com/gtag/js?id=G-409L9J2E0X'
                    strategy='afterInteractive'
                />
                <Script id='google-analytics' strategy='afterInteractive'>
                    {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){window.dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'G-409L9J2E0X');
        `}
                </Script>

                <Script id='clarityScript' strategy='afterInteractive'>
                    {`
          (function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
    })(window, document, "clarity", "script", "i7tjie7qeh");
        `}
                </Script>
                <Provider store={store}>
                    <Layout>
                        <Component {...pageProps} />
                    </Layout>
                </Provider>
            </main>
            <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
    );
};

export default App;

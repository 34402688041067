type LayoutProps = {
    children: React.ReactNode;
};

export default function Layout({ children }: LayoutProps) {
    return (
        <>
            <main id='layout-main'>
                <div className='w-full text-black'>{children}</div>
            </main>
        </>
    );
}

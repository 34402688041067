//axiosInstance.ts
import axios from 'axios';

const instance = axios.create({
    baseURL: process.env.NEXT_PUBLIC_API_URL,
    headers: {
        'Content-Type': 'application/json'
        // Add your default headers here
    }
});

// Add a request interceptor
instance.interceptors.request.use(
    function (config) {
        // Insert api_key or other headers here
        config.headers.api_key = process.env.NEXT_PUBLIC_API_KEY;
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

export default instance;
